import React from 'react';
import './ProgressBar.css'; // Import styles for the progress bar

function ProgressBar({ progress, goal }) {
  const percentage = Math.min((progress / goal) * 100, 100); // Calculate percentage with a cap at 100%

  return (
    <div className="progress-bar-container">
      <div className="progress-bar">
        <div
          className="progress-bar-fill"
          style={{ width: `${percentage}%` }}
          aria-label={`Progress: ${progress} of ${goal}`}
        ></div>
      </div>
      <div className="progress-bar-text">
        {progress} / {goal} TILL PROJECT UPDATE
      </div>
    </div>
  );
}

export default ProgressBar;
