import React, { useState, useEffect } from 'react';
import './Header.css';

const PUMP_FUN_CONTRACT_ADDRESS = '6roW1eRgemAWfiw882Gb5TDJ9BzHrU4ihELeN6bWpump';

const Header = () => {
  const [tokenData, setTokenData] = useState(null);

  useEffect(() => {
    const fetchTokenData = async () => {
      try {
        const response = await fetch(`https://api.dexscreener.com/latest/dex/tokens/${PUMP_FUN_CONTRACT_ADDRESS}`);
        const data = await response.json();
        const targetPair = data.pairs.find(pair => pair.chainId === "solana" && pair.dexId === "raydium");

        if (targetPair) {
          setTokenData(targetPair);
        } else {
          console.log("No pair found with Solana and Raydium.");
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchTokenData();
  }, []);

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        alert('Address copied to clipboard!');
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
      });
  };

  if (!tokenData) {
    return <div className="header-container loading"></div>;
  }

  return (
    <div className="header-container">
      {/* Token Image */}
      <div className="token-image-container">
        <img
          src={tokenData.info.imageUrl}
          alt={`${tokenData.baseToken.name} logo`}
          className="token-image"
        />
      </div>

      {/* Token Details */}
      <div className="token-details">
        <h1 className="token-name">
          {tokenData.baseToken.name} ({tokenData.baseToken.symbol})
        </h1>
        <p className="token-address">
          <span onClick={() => handleCopy(tokenData.baseToken.address)}>
            Contract Address: {tokenData.baseToken.address}
          </span>
          <button
            onClick={() => handleCopy(tokenData.baseToken.address)}
            className="copy-button"
            title="Copy Contract Address"
          >
            📋
          </button>
        </p>
      </div>

      {/* Token Stats */}
      <div className="token-stats">
        <div className="stat-item">
          <strong>Liquidity:</strong> ${tokenData.liquidity.usd.toLocaleString()}
        </div>
        <div className="stat-item">
          <strong>Market Cap:</strong> ${tokenData.marketCap.toLocaleString()}
        </div>
        <div className="stat-item">
          <strong>Price (SOL):</strong> {tokenData.priceNative}
        </div>
        <div className="stat-item">
          <strong>Price (USD):</strong> ${tokenData.priceUsd}
        </div>
        <div className="stat-item">
          <strong>24h Volume:</strong> ${tokenData.volume.h24.toLocaleString()}
        </div>
      </div>

      {/* Social Links */}
      <div className="social-links">
        <a href={tokenData.info.socials[0]?.url} target="_blank" rel="noopener noreferrer" className="social-link">
          Twitter
        </a>
        <a href={tokenData.info.socials[1]?.url} target="_blank" rel="noopener noreferrer" className="social-link">
          Telegram
        </a>
        <a href={'https://caninecollective.gitbook.io/paw-genesis-whitepaper-docs'} target="_blank" rel="noopener noreferrer" className="social-link">
          Whitepaper
        </a>
        <a
          href={`https://pump.fun/coin/${PUMP_FUN_CONTRACT_ADDRESS}`}
          target="_blank"
          rel="noopener noreferrer"
          className="pumpfun-link"
        >
          Pump.fun
        </a>
      </div>
    </div>
  );
};

export default Header;
