import React, { useState, useEffect, useCallback } from 'react';
import './App.css';
import Header from './Header';
import NFTMarketplace from './NFTMarketplace';
import ProgressBar from './ProgressBar'; // Import the ProgressBar module

function App() {
  const [nfts, setNfts] = useState([]);
  const [progress, setProgress] = useState(0); // Progress for the progress bar
  const [masterJson, setMasterJson] = useState([]); // Stores the full master.json content
  const [startIndex, setStartIndex] = useState(0); // Tracks the starting index for batch fetching
  const [isFetching, setIsFetching] = useState(false); // Prevents duplicate fetches
  const [isLoading, setIsLoading] = useState(true); // Tracks initial loading state
  const bucketUrl = 'https://kickstop.us';
  const batchSize = 40; // Number of NFTs to fetch per batch

  // Fetch master.json to initialize the list of NFTs
  const fetchMasterJson = useCallback(async () => {
    try {
      const response = await fetch(`${bucketUrl}/master.json`);
      const data = await response.json();
      setMasterJson(data.reverse()); // Reverse to start with the most recent NFTs
      return data;
    } catch (error) {
      console.error('Error fetching master.json:', error);
      return [];
    }
  }, [bucketUrl]);

  // Fetch NFTs for the current batch
  const fetchNFTs = useCallback(
    async (start) => {
      if (isFetching || !masterJson.length) return;
      setIsFetching(true);

      try {
        const batch = masterJson.slice(start, start + batchSize);

        const nftData = await Promise.all(
          batch.map(async (txnId) => {
            const imageUrl = `${bucketUrl}/images/${txnId}.png`;
            const metadataUrl = `${bucketUrl}/metadata/${txnId}.json`;

            const metadataResponse = await fetch(metadataUrl);
            const metadata = await metadataResponse.json();

            return {
              id: txnId, // Unique identifier
              name: metadata.name,
              image_url: imageUrl,
              metadata: metadata,
            };
          })
        );

        // Prevent duplicate entries by checking if the ID already exists
        setNfts((prevNfts) => {
          const uniqueNfts = nftData.filter(
            (nft) => !prevNfts.some((prevNft) => prevNft.id === nft.id)
          );
          return [...prevNfts, ...uniqueNfts];
        });

        setProgress(masterJson.length); // Update progress
      } catch (error) {
        console.error('Error fetching NFT data:', error);
      } finally {
        setIsFetching(false);
        setIsLoading(false); // Turn off initial loading after the first batch
      }
    },
    [isFetching, masterJson, bucketUrl, batchSize]
  );

  // Initial setup: Fetch master.json and load the first batch
  useEffect(() => {
    const initialize = async () => {
      const data = await fetchMasterJson();
      if (data.length) await fetchNFTs(0); // Load the first batch
    };

    initialize();
  }, [fetchMasterJson, fetchNFTs]);

  // Infinite scroll listener
  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop >=
          document.documentElement.offsetHeight - 100 &&
        !isFetching &&
        startIndex + batchSize < masterJson.length
      ) {
        setStartIndex((prevIndex) => prevIndex + batchSize); // Increment the starting index for the next batch
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isFetching, startIndex, masterJson.length, batchSize]);

  // Fetch new NFTs whenever startIndex changes
  useEffect(() => {
    fetchNFTs(startIndex);
  }, [fetchNFTs, startIndex]);

  return (
    <div className="App">
      <Header />
      <ProgressBar progress={progress} goal={9999} /> {/* Add ProgressBar */}
      {isLoading ? (
        <div className="loading-placeholder">Loading NFTs...</div> // Initial loading placeholder
      ) : (
        <>
          <NFTMarketplace nfts={nfts} />
          {isFetching && (
            <div className="infinite-scroll-loader"> {/* Infinite scroll loader */}
              <div className="spinner"></div>
              <p>Loading more NFTs...</p>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default App;
