import React, { useState } from 'react';
import './NFTMarketplace.css';

function NFTMarketplace({ nfts }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOption, setSortOption] = useState('recent');
  const [overlayImage, setOverlayImage] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState({});

  const allAttributes = nfts
    .flatMap((nft) =>
      nft.metadata.attributes
        .filter((attr) => attr.trait_type.toLowerCase() !== 'seed') // Exclude "seed" from attributes
        .map((attr) => attr.trait_type)
    )
    .filter((value, index, self) => self.indexOf(value) === index);

  const attributeOptions = {};
  allAttributes.forEach((attribute) => {
    attributeOptions[attribute] = [
      ...new Set(
        nfts.flatMap((nft) =>
          nft.metadata.attributes
            .filter(
              (attr) =>
                attr.trait_type === attribute &&
                attr.trait_type.toLowerCase() !== 'seed' // Ensure seed is excluded
            )
            .map((attr) => attr.value)
        )
      ),
    ];
  });

  const sortedNfts = [...nfts].sort((a, b) => {
    if (sortOption === 'recent') {
      return parseInt(b.name.match(/\d+/), 10) - parseInt(a.name.match(/\d+/), 10);
    } else if (sortOption === 'oldest') {
      return parseInt(a.name.match(/\d+/), 10) - parseInt(b.name.match(/\d+/), 10);
    }
    return 0;
  });

  const filteredNfts = sortedNfts.filter((nft) => {
    const matchesSearch = nft.name.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesFilters = Object.keys(selectedFilters).every((trait) => {
      if (!selectedFilters[trait]) return true;
      return nft.metadata.attributes.some(
        (attr) => attr.trait_type === trait && attr.value === selectedFilters[trait]
      );
    });
    return matchesSearch && matchesFilters;
  });

  const shortenSeed = (seed) => {
    return `${seed.slice(0, 4)}...${seed.slice(-4)}`;
  };

  const showOverlay = (image) => {
    setOverlayImage(image);
  };

  const closeOverlay = () => {
    setOverlayImage(null);
  };

  return (
    <div className="marketplace">
      <div className="controls">
        <input
          type="text"
          placeholder="Search NFTs..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-bar"
        />
        <select
          value={sortOption}
          onChange={(e) => setSortOption(e.target.value)}
          className="sort-dropdown"
        >
          <option value="recent">Most Recent</option>
          <option value="oldest">Oldest</option>
        </select>
        {Object.keys(attributeOptions).map((trait) => (
          <select
            key={trait}
            value={selectedFilters[trait] || ''}
            onChange={(e) =>
              setSelectedFilters((prev) => ({
                ...prev,
                [trait]: e.target.value || null,
              }))
            }
            className="filter-dropdown"
          >
            <option value="">{`Filter by ${trait}`}</option>
            {attributeOptions[trait].map((value) => (
              <option key={value} value={value}>
                {value}
              </option>
            ))}
          </select>
        ))}
      </div>

      <div className="nft-grid">
        {filteredNfts.map((nft, index) => (
          <div key={index} className="nft-card">
            <img
              src={nft.image_url}
              alt={nft.name}
              className="nft-image"
              onClick={() => showOverlay(nft.image_url)}
            />
            <div className="nft-details">
              <h2 title={nft.name} className="nft-name">
                {nft.name}
              </h2>
              <ul className="metadata-list">
                {nft.metadata.attributes
                  .filter((attr) => attr.trait_type.toLowerCase() !== 'seed') // Exclude seed from attributes list
                  .map((attr, idx) => (
                    <li key={idx} className="metadata-item">
                      <strong>{attr.trait_type}:</strong> {attr.value}
                    </li>
                  ))}
                <li className="metadata-item">
                  <strong>Seed:</strong>{' '}
                  <a
                    href={`https://solscan.io/tx/${nft.metadata.attributes[2].value}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="seed-link"
                  >
                    {shortenSeed(nft.metadata.attributes[2].value)}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        ))}
      </div>

      {overlayImage && (
        <div className="image-overlay" onClick={closeOverlay}>
          <img src={overlayImage} alt="NFT Preview" />
        </div>
      )}
    </div>
  );
}

export default NFTMarketplace;
